import Alpine from 'alpinejs';
import TomSelect from 'tom-select';
import type { TomLoadCallback } from 'tom-select/dist/types/types';

Alpine.data('select', () => ({
    observer: null as MutationObserver | null,
    init() {
        const options = this.$el?.dataset.options ? JSON.parse(this.$el?.dataset.options) : [];
        const multi = Boolean(this.$el?.dataset.multi);
        const endpoint = this.$el?.dataset.endpoint;
        const creatable = Boolean(this.$el?.dataset.creatable);

        const root = this.$root;
        const config = {
            childList: true, // Watch only child changes
            subtree: true, // Include all descendants
        };

        const handleMutation = () => {
            // Disconnect observer temporarily to prevent recursive loops
            this.observer?.disconnect();

            try {
                /* @ts-expect-error this is bound to Alpine component context - see bind call below */
                const input = this.$el.querySelector('input');

                if (!input) return;

                /* @ts-expect-error this is bound to Alpine component context - see bind call below */
                this.$el.style.setProperty('--ts-pr-caret', '1rem');

                // Avoid reinitializing TomSelect if already initialized
                // @ts-expect-error stored on input element I think?
                if (!input.tomselect) {
                    const inputValue = input.getAttribute('value');

                    new TomSelect(input, {
                        allowEmptyOption: false,
                        plugins: ['clear_button', 'remove_button'],
                        maxOptions: undefined,
                        maxItems: multi ? null : 1,
                        hidePlaceholder: true,
                        create: creatable,
                        valueField: 'value',
                        labelField: 'label',
                        searchField: ['label'],
                        items: inputValue ? JSON.parse(inputValue) : [],
                        options,
                        createFilter: (input: string) => input.length >= 2,
                        load: endpoint
                            ? async (query: string, callback: TomLoadCallback) => {
                                  const url = `${endpoint}?filter[search]=${query}&optionsQuery=true`;

                                  try {
                                      const response = await fetch(url);
                                      callback(await response.json(), []);
                                  } catch (error) {
                                      callback([], []);
                                  }
                              }
                            : undefined,
                    });
                }
            } finally {
                // Re-enable the observer after handling mutations
                this.observer?.observe(root, config);
            }
        };

        // Initial call to handle the current DOM state
        handleMutation();

        // Initialize the MutationObserver
        this.observer = new MutationObserver(handleMutation);
        this.observer.observe(root, config);
    },
    destroy() {
        // Disconnect the observer on component destruction
        if (this.observer) {
            this.observer.disconnect();
        }
    },
}));

/* USAGE
<x-alpine-select
    label="Users"
    name="users"

    options='[{"label":"hi","value":"hi"}]' // optional
    endpoint="" // optional
    creatable // optional
    multi // optional (default = single)
/>
*/
