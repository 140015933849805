import Alpine from 'alpinejs';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import 'flatpickr/dist/flatpickr.css';
import type { BaseOptions } from 'flatpickr/dist/types/options';
import './datepicker.css';
import Cleave from 'cleave.js';

type Props = {
    options: BaseOptions;
    isMonthly: boolean;
};

Alpine.data('datepicker', ({ options, isMonthly }: Props) => {
    return {
        init() {
            const input = this.$el as HTMLInputElement;

            function mergeOptionsWithMonthSelectPlugin(options: BaseOptions, isMonthly: boolean) {
                if (isMonthly) {
                    return {
                        ...options,
                        plugins: [
                            monthSelectPlugin({
                                shorthand: true,
                                dateFormat: options.dateFormat,
                            }),
                        ],
                    };
                }

                return options;
            }

            if (isMonthly) {
                // Allows users to enter month year without a slash
                new Cleave(input, {
                    date: true,
                    datePattern: ['m', 'Y'],
                });
            }

            flatpickr(input, mergeOptionsWithMonthSelectPlugin(options, isMonthly));
        },
    };
});
